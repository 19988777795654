import { AuthGuard } from './auth/auth.guard';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';

export const appRoutes: VexRoutes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent)
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./pages/login/forgot-password/forgot-password.component').then(
        (m) => m.ForgotPasswordComponent
      )
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./pages/login/reset-password/reset-password.component').then(
        (m) => m.ResetPasswordComponent
      ),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/apps/instrucciones/instrucciones.component').then(
            (m) => m.InstruccionesComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['All'] },
      }
    ]
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'instrucciones',
        loadComponent: () =>
          import('./pages/apps/instrucciones/instrucciones.component').then(
            (m) => m.InstruccionesComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['0'] } //'Administrador',
      }
    ]
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'generar-pedido', //apps/generar-pedido
        loadComponent: () =>
          import('./pages/apps/generar-pedido/generar-pedido.component').then(
            (m) => m.GenerarPedidoComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['0'] } //'Administrador',
      }
    ]
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'pedidos', //apps/generar-pedido
        loadComponent: () =>
          import('./pages/apps/pedidos/pedidos.component').then(
            (m) => m.PedidosComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['0'] } //'Administrador',
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/login'
  }
];
