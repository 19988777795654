import { Inject, Injectable } from '@angular/core';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { EncrDecrService } from 'src/app/services/encr-decr-service.service';
import { Settings } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  decrypted: any;
  id: any;

  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private EncrDecr: EncrDecrService) {
    if (localStorage.getItem('log')) {
      this.decrypted = this.EncrDecr.get(
        '123456$#@$^@1ERF',
        localStorage.getItem('log')
      );
      this.id = this.EncrDecr.get(
        '123456$#@$^@1ERF',
        localStorage.getItem('keylog')
      );
    }
    Settings.defaultLocale = this.id;
    this.loadNavigation();
  }

  loadNavigation(): void {
    if (this.decrypted == 0) {
      //'Administrador'
      this._items.next([
        // {
        //   type: 'subheading',
        //   label: 'Dashboards',
        //   children: [
        //     {
        //       type: 'link',
        //       label: 'Analytics',
        //       route: '/',
        //       icon: 'mat:insights',
        //       routerLinkActiveOptions: { exact: true }
        //     }
        //   ]
        // },
        {
          type: 'subheading',
          label: 'Datos',
          children: [
            {
              type: 'link',
              label: 'Intrucciones',
              route: '/',
              icon: 'mat:insights',
              routerLinkActiveOptions: { exact: true }
            }
          ]
        },
        {
          type: 'subheading',
          label: 'Apps',
          children: [
            // {
            //   type: 'link',
            //   label: 'Instrucciones',
            //   route: '/apps/instrucciones',
            //   icon: 'mat:assignment' //description
            // },
            {
              type: 'link',
              label: 'Realizar Pedido',
              route: '/apps/generar-pedido',
              icon: 'mat:credit_card' //add_shopping_cart
            },
            {
              type: 'link',
              label: 'Mis Pedidos',
              route: '/apps/pedidos',
              icon: 'mat:shopping_cart' //print
            }
          ]
        }
      ]);
    }
  }
}
