import { Component, OnInit } from '@angular/core';
import { VexPopoverRef } from '@vex/components/vex-popover/vex-popover-ref';
import { MatRippleModule } from '@angular/material/core';
import { Router, RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { UserService } from 'src/app/services/user.service';
import { AccountSettingsComponent } from '../../../../pages/apps/components/account/account-settings/account-settings.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'vex-sidenav-user-menu',
  templateUrl: './sidenav-user-menu.component.html',
  styleUrls: ['./sidenav-user-menu.component.scss'],
  imports: [MatRippleModule, RouterLink, MatIconModule],
  standalone: true
})
export class SidenavUserMenuComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private readonly popoverRef: VexPopoverRef,
    public service: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  close(): void {
    setTimeout(() => this.popoverRef.close(), 250);
    this.service.logout().subscribe(
      (data) => {
        localStorage.clear();
        this.router.navigate(['/login']);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  change_data() {
    this.dialog.open(AccountSettingsComponent, {
      width: '600px'
      // data: data,
    });
  }
}
