import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const path = environment.apiConfig.path;
@Injectable({
  providedIn: 'root'
})

export class UserService {
  httpOptions: { headers: HttpHeaders; };
  urls!: string;
  token!: any;


  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('token');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
    };
  }

  getProfile(): Observable<any> {
    this.urls = path + "/profile";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  logout(): Observable<any> {
    this.urls = path + "/logout";
    return this.http.get(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

}
