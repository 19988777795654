import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const path = environment.apiConfig.path;
@Injectable({
  providedIn: 'root'
})
export class AccountService {
  httpOptions: { headers: HttpHeaders };
  urls!: string;
  token!: any;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('token');
    this.httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token
      })
    };
  }

  updatePassword(password: string, password_new: string): Observable<any> {
    this.urls = path + '/updatePassword';
    var datoaEnviar = {
      password: password,
      password_new: password_new
    };
    return this.http.put(this.urls, datoaEnviar, this.httpOptions).pipe(
      tap((data: any) => {
        return of(data);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
