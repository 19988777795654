import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe, NgIf } from '@angular/common';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { AdiButtonsModule } from 'src/app/buttons/buttons.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployeService } from 'src/app/services/employe.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AccountService } from 'src/app/services/account.service';
@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  standalone: true,
  imports: [AngularMaterialModule, ReactiveFormsModule, AdiButtonsModule, NgIf],
  providers: [DatePipe]
})
export class AccountSettingsComponent implements OnInit {
  loading = { 1: false, 2: false, 3: false, 4: false };

  hidePassword: boolean = true;
  hidePassword_new: boolean = true;

  constructor(
    private dialogRef: MatDialogRef<AccountSettingsComponent>,
    private snackbar: MatSnackBar,
    private service: AccountService,
    public datepipe: DatePipe,
    private fb: FormBuilder
  ) {}
  form = this.fb.group({
    // email: ['', Validators.required],
    email: [''],
    password: ['', Validators.required],
    password_new: ['', Validators.required]
  });

  ngOnInit() {}

  save_data(i: number) {
    this.loading[i as 1 | 2 | 3 | 4] = true;
    if (this.form.valid) {
      const password = this.form.value.password ?? ''; // Using nullish coalescing operator
      const new_password = this.form.value.password_new ?? '';
      this.service.updatePassword(password, new_password).subscribe({
        next: (data: any) => {
          console.log(data);
          this.dialogRef.close(true);
          this.message('Datos actualizados');
        },
        error: (err) => {
          this.loading[i as 1 | 2 | 3 | 4] = false;
          const errorData = err.error.data;
          Object.keys(errorData).forEach((key) => {
            const control = this.form.get(key);
            if (control) {
              control.setErrors({ customError: errorData[key] });
            }
          });
        },
        complete: () => console.log('completado')
      });
    } else {
      this.loading[i as 1 | 2 | 3 | 4] = true;
      this.message('Verificar los datos');
    }
  }
  message(m: string) {
    this.snackbar.open(m, 'OK', {
      duration: 3000
    });
  }
}
