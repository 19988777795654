<form [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      Cambiar Contraseña
    </h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">

    <!-- <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Email</mat-label>
        
        <input formControlName="email" matInput />
        <mat-icon matIconPrefix svgIcon="mat:email"></mat-icon>
      </mat-form-field>
    </div> -->

    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Contraseña Actual</mat-label>
        <input formControlName="password" matInput [type]="hidePassword ? 'password' : 'text'" />
        <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hidePassword">
          <mat-icon>{{
            hidePassword ? "visibility_off" : "visibility"
            }}</mat-icon>
        </button>
        <mat-error *ngIf="form.get('password')?.errors?.['customError']">
          {{ form.get("password")?.errors?.["customError"] }}
        </mat-error>
        <mat-icon matIconPrefix svgIcon="mat:lock"></mat-icon>
      </mat-form-field>
    </div>
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Nueva Contraseña</mat-label>
        <input formControlName="password_new" matInput [type]="hidePassword_new ? 'password' : 'text'" minlength="5" />
        <button mat-icon-button matSuffix (click)="hidePassword_new = !hidePassword_new"
          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword_new">
          <mat-icon>{{
            hidePassword_new ? "visibility_off" : "visibility"
            }}</mat-icon>
        </button>
        <mat-error *ngIf="form.get('password_new')?.errors?.['customError']">
          {{ form.get("password_new")?.errors?.["customError"] }}
        </mat-error>
        <mat-icon matIconPrefix svgIcon="mat:lock"></mat-icon>
      </mat-form-field>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancelar</button>
    <button class="btn-block" adiButton [loading]="loading[1]" (click)="save_data(1)" cdkFocusInitial>
      Guardar
      <ng-template adiButtonLoader> Guardando... </ng-template>
    </button>
  </mat-dialog-actions>
</form>